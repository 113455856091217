import { useCallback, useMemo, type MouseEvent } from 'react';

import { compact } from 'lodash';

import { OrderFormSides } from '../../../types/OrderFormSides';
import { getPositionAmount } from '../../../utils';
import { Button, ButtonGroup, ButtonVariants } from '../../Button';
import { FormControlSizes, Input, SearchSelect, type FormControlProps } from '../../Form';
import { QuickOptions } from '../../QuickOptions';
import { Text } from '../../Text';
import { QuickOptionsContainer } from '../styles';
import { useAmountInput } from '../useAmountInput';

import { defineMessages } from 'react-intl';
import type { IntlWithFormatter } from '../../../contexts/IntlContext';
import { useIntl } from '../../../hooks';
import type { Security } from '../../../types/Security';

const messages = defineMessages({
  contract: {
    defaultMessage: 'Contract',
    id: 'Forms.QuantityInput.contract',
  },
  max: {
    defaultMessage: 'Max',
    id: 'Forms.QuantityInput.max',
  },
  numContractEqualsValueCurrency: {
    defaultMessage: '1 contract = {value} {currency}',
    id: 'Forms.QuantityInput.numContractEqualsValueCurrency',
  },
  valuePercentage: {
    defaultMessage: '{value}%',
    id: 'Forms.QuantityInput.valuePercentage',
  },
});

const QTY_QUICK_OPTIONS = [
  {
    label: '10%',
    value: 0.1,
  },
  {
    label: '25%',
    value: 0.25,
  },
  {
    label: '50%',
    value: 0.5,
  },
  {
    label: '75%',
    value: 0.75,
  },
  {
    label: 'Max',
    value: 1,
  },
];
const quickOptionsRegex = /^(\d+)([%])$/;

function generateQtyQuickOptions(intl: IntlWithFormatter) {
  return QTY_QUICK_OPTIONS.reduce<typeof QTY_QUICK_OPTIONS>((acc, option) => {
    const [_, value] = quickOptionsRegex.exec(option.label) ?? [];
    const label = value ? intl.formatMessage(messages.valuePercentage, { value }) : intl.formatMessage(messages.max);

    return acc.concat({
      ...option,
      label,
    });
  }, []);
}

export type QuantityInputProps = {
  currency?: string;
  side: OrderFormSides;
  security: Security | undefined;
  value: string;
  allowCurrencyChange?: boolean;
  allowQtyInContracts?: boolean;
  allowedCurrencies?: string[];
  min?: string;
  max?: string;
  onCurrencyChange: (currency: string) => void;
  onChange: (value?: string) => void;
  onQuickOptionClicked?: (value: number) => void;
  showQuickOptions?: boolean;
  disableQuickOptions?: boolean;
  currencyDisabled?: boolean; // When modifying we can change quantity but not ccy for example
} & Omit<FormControlProps<HTMLInputElement>, 'security' | 'onChange' | 'value'>;

export const QuantityInput = ({
  allowCurrencyChange = true,
  allowQtyInContracts = false,
  allowedCurrencies = undefined,
  currency,
  disabled,
  currencyDisabled = false,
  security,
  side,
  value,
  showQuickOptions = false,
  disableQuickOptions,
  onChange,
  onCurrencyChange,
  onQuickOptionClicked,
  min,
  max,
  ...props
}: QuantityInputProps) => {
  const intl = useIntl();
  const qtyQuickOptions = useMemo(() => generateQtyQuickOptions(intl), [intl]);
  if (showQuickOptions && onQuickOptionClicked == null) {
    throw new Error('Cannot show quick options without quick option handler');
  }

  const inputProps = useAmountInput({
    currency: currency ?? undefined,
    asset: security,
    value,
    onChange,
    min,
    max,
  });

  const handleChangeCurrency = useCallback(
    (currency: string) => {
      onCurrencyChange(currency);
      inputProps.ref.current?.focus();
    },
    [onCurrencyChange, inputProps]
  );

  const { BaseCurrency, QuoteCurrency } = security ?? {};

  return (
    <>
      <Input
        {...props}
        {...inputProps}
        data-currency={currency}
        data-allowed-currencies={allowedCurrencies?.map(currency => currency || 'Contract').join(', ')}
        disabled={disabled}
        suffix={
          allowCurrencyChange && security ? (
            <>
              {!allowQtyInContracts ? (
                <CurrencyButtons
                  disabled={disabled || currencyDisabled}
                  allowedCurrencies={allowedCurrencies}
                  currency={currency}
                  side={side}
                  handleChangeCurrency={handleChangeCurrency}
                  BaseCurrency={BaseCurrency}
                  QuoteCurrency={QuoteCurrency}
                />
              ) : (
                <CurrencySelect
                  disabled={disabled || currencyDisabled}
                  allowedCurrencies={allowedCurrencies}
                  currency={currency}
                  side={side}
                  security={security}
                  handleChangeCurrency={handleChangeCurrency}
                  BaseCurrency={BaseCurrency}
                  QuoteCurrency={QuoteCurrency}
                  allowCurrencyChange={allowCurrencyChange}
                />
              )}
            </>
          ) : (
            <Text pr="spacingSmall">
              {allowQtyInContracts && (currency === '' || currency === undefined) ? 'Contract' : currency}
            </Text>
          )
        }
        type="number"
      />
      {showQuickOptions && onQuickOptionClicked != null && (
        <QuickOptionsContainer>
          <QuickOptions
            options={qtyQuickOptions}
            onOptionClick={onQuickOptionClicked}
            disabled={disabled || disableQuickOptions}
          />
        </QuickOptionsContainer>
      )}
    </>
  );
};

const getVariant = (side: OrderFormSides) => {
  switch (side) {
    case OrderFormSides.Buy: {
      return ButtonVariants.Positive;
    }
    case OrderFormSides.Sell: {
      return ButtonVariants.Negative;
    }
    case OrderFormSides.Twoway: {
      return ButtonVariants.Primary;
    }
  }
};

type CurrencyControlsProps = Pick<
  QuantityInputProps,
  'disabled' | 'allowedCurrencies' | 'currency' | 'side' | 'allowCurrencyChange'
> & {
  handleChangeCurrency: (currency: string) => void;
  BaseCurrency?: string;
  QuoteCurrency?: string;
};

export function CurrencyButtons({
  disabled,
  allowedCurrencies,
  currency,
  side,
  handleChangeCurrency,
  BaseCurrency,
  QuoteCurrency,
}: CurrencyControlsProps) {
  const handleValueChange = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const currency = e.currentTarget.value;
      handleChangeCurrency(currency);
    },
    [handleChangeCurrency]
  );

  return (
    <ButtonGroup size={FormControlSizes.Small} style={{ alignSelf: 'center' }}>
      <Button
        disabled={disabled || (allowedCurrencies && BaseCurrency != null && !allowedCurrencies.includes(BaseCurrency))}
        variant={currency === BaseCurrency ? getVariant(side) : ButtonVariants.Default}
        onClick={handleValueChange}
        value={BaseCurrency}
        data-testid="quantity-input-base-currency-button"
      >
        {BaseCurrency}
      </Button>
      {showQuoteCurrency(BaseCurrency, QuoteCurrency) && (
        <Button
          disabled={
            disabled || (allowedCurrencies && QuoteCurrency != null && !allowedCurrencies.includes(QuoteCurrency))
          }
          variant={currency === QuoteCurrency ? getVariant(side) : ButtonVariants.Default}
          onClick={handleValueChange}
          value={QuoteCurrency}
          data-testid="quantity-input-quote-currency-button"
        >
          {QuoteCurrency}
        </Button>
      )}
    </ButtonGroup>
  );
}

interface Item {
  label: string;
  value: string;
  description: string;
}

const getLabel = item => item?.label ?? '';
const getDescription = item => item?.description ?? '';

function CurrencySelect({
  security,
  allowedCurrencies,
  currency,
  handleChangeCurrency,
  BaseCurrency,
  QuoteCurrency,
  disabled,
}: CurrencyControlsProps & { security: Security }) {
  const positionAmount = useMemo(() => getPositionAmount(1, security), [security]);

  const items = useMemo<Item[]>(() => {
    return compact([
      {
        label: BaseCurrency ?? '',
        value: BaseCurrency ?? '',
        description: '',
      },
      showQuoteCurrency(BaseCurrency, QuoteCurrency)
        ? {
            label: QuoteCurrency ?? '',
            value: QuoteCurrency ?? '',
            description: '',
          }
        : null,
      {
        label: 'Contract',
        value: '',
        description: `1 contract = ${positionAmount.value} ${positionAmount.currency}`,
      },
    ]);
  }, [BaseCurrency, QuoteCurrency, positionAmount]);

  const selectedItem = useMemo(() => items.find((item => item.value === currency) || items[0]), [currency, items]);

  const handleChange = useCallback(
    (selected?: Item) => {
      if (selected != null) {
        handleChangeCurrency(selected.value);
      }
    },
    [handleChangeCurrency]
  );

  const isItemDisabled = useCallback(
    item => {
      return Boolean(allowedCurrencies && !allowedCurrencies.includes(item.value));
    },
    [allowedCurrencies]
  );

  return (
    <SearchSelect
      data-testid="quantity-currency-select"
      selection={selectedItem}
      options={items}
      getLabel={getLabel}
      disabled={disabled}
      getDescription={getDescription}
      isItemDisabled={isItemDisabled}
      dropdownWidth="320px"
      dropdownPlacement="bottom-start"
      dropdownSize={FormControlSizes.Default}
      onChange={handleChange}
      size={FormControlSizes.Small}
      showDropdownSearch={false}
      initialSortByLabel={false}
      portalize
    />
  );
}

// Options have BaseCurrency equal to QuoteCurrency, we don't want to show
// same currency twice [UI-2628]
const showQuoteCurrency = (BaseCurrency?: string, QuoteCurrency?: string) => {
  return BaseCurrency !== QuoteCurrency;
};
