import {
  DEFAULT_LOCALE,
  IntlProvider as SharedIntlProvider,
  logger,
  supportedLocales,
  useWLCustomerUserConfigContext,
  useWLOrgConfigContext,
  type IntlProviderProps as SharedIntlProviderProps,
} from '@talos/kyoko';
import { useCallback, useEffect, useState, type PropsWithChildren } from 'react';
import { firstValueFrom } from 'rxjs';

function getBrowserLocale() {
  return window.navigator.language;
}

export interface IntlProviderProps extends Omit<SharedIntlProviderProps, 'loadMessages'> {}

export function IntlProvider({ children, ...props }: PropsWithChildren<IntlProviderProps>) {
  const { loadedConfig } = useWLOrgConfigContext();
  const browserLocale = getBrowserLocale();

  const { config } = useWLCustomerUserConfigContext();
  const [locale, setLocale] = useState<string | undefined>(undefined);

  useEffect(() => {
    setLocale(() => {
      const preferredLocale = config.locale ?? browserLocale;
      return supportedLocales.map(locale => locale.value).includes(preferredLocale) ? preferredLocale : DEFAULT_LOCALE;
    });
  }, [config, browserLocale]);

  const loadMessages = useCallback(
    (locale: string) => {
      return {
        messages: loadWhitelabelTranslations(),
        overrides: loadWLConfigTranslationOverrides(),
      };

      function loadWhitelabelTranslations() {
        return import(`../translations/whitelabel.${locale}.json`)
          .then(mod => mod.default)
          .catch(error => {
            if (locale.includes('-')) {
              const language = locale.split('-')[0];
              return import(`../translations/whitelabel.${language}.json`)
                .then(mod => mod.default)
                .catch(error => {
                  logger.error(new Error(`Failed to load messages for ${locale} (${language})`));
                  throw error;
                });
            }
            logger.error(new Error(`Failed to load messages for ${locale}`));
            throw error;
          });
      }

      function loadWLConfigTranslationOverrides() {
        return firstValueFrom(loadedConfig).then(config => {
          const translations = config.translations;
          const localeSubtag = locale.split('-')[0];
          return Promise.resolve(translations?.[locale] || translations?.[localeSubtag] || {});
        });
      }
    },
    [loadedConfig]
  );

  if (locale == null) {
    // Whitelabel UI checks your config for locale preferences before rendering the IntlProvider
    return null;
  }

  return (
    <SharedIntlProvider {...props} loadMessages={loadMessages} locale={locale}>
      {children}
    </SharedIntlProvider>
  );
}
