import type { LocaleItem } from './types';

export const supportedLocales: LocaleItem[] = [
  {
    displayName: 'English',
    flag: '🇺🇸',
    value: 'en-US',
  },
  // {
  //   displayName: 'Deutsch',
  //   flag: '🇩🇪',
  //   value: 'de',
  // },
  {
    displayName: 'Testing',
    flag: '✅',
    value: 'testing',
  },
];
