import { HStack } from '@talos/kyoko';
import type { ReactNode } from 'react';

interface SummaryLineProps {
  title: ReactNode;
  value: ReactNode;
}

export const SummaryLine = ({ title, value }: SummaryLineProps) => {
  return (
    <HStack justifyContent="space-between">
      {title}
      {value}
    </HStack>
  );
};
