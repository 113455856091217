import type { Store } from '@reduxjs/toolkit';
import { Observable, firstValueFrom, timeout } from 'rxjs';
import { v4 as uuid, v1 as uuid1 } from 'uuid';

import { findLast, isDate } from 'lodash';
import { isDateParameterValue } from '../../../components/Forms';
import { DateField } from '../../../fields';
import {
  NEW_ORDER_SINGLE,
  ORDER,
  ORDER_CANCEL_REQUEST,
  ORDER_CONTROL_REQUEST,
  ORDER_MODIFY_REQUEST,
} from '../../../tokens';
import {
  ErrorActionEnum,
  ExecTypeEnum,
  OrdStatusEnum,
  OrdTypeEnum,
  OrderControlActionEnum,
  TimeInForceEnum,
  type CustomerOrder,
  type WLOrderStrategy,
  type WLOrderStrategyParameter,
  type WhitelabelParametersFormState,
} from '../../../types';
import { formattedDateForSubscription } from '../../../utils';
import type { IWebSocketClient } from '../../WebSocketClient';
import { EXEC_REPORT_RESPONSE_TIMEOUT_MS } from '../tokens';
import {
  cleanState,
  selectError,
  selectOrderStep,
  setError,
  setOrderStep,
  type OrderFormState,
  type OrderState,
  type RootState,
} from './../state';
import type { WhiteLabelNewOrderSingle, WhiteLabelOrderCancelReplace } from './types';

// Its what backend expects. not ClOrdId, it expects ClOrderID.
// https://docs.talostrading.com/#customer-websocket-subscriptions:~:text=this%20execution%20report.-,Order,-Request%20stream%20of
// When this is fixed in the back-end, this should be changed to ClOrdID
type OrderActionRequest = {
  name: string;
  ClOrderID: string;
  ClOrdID: string;
  tag: string;
};

/**
 * Handles websocket communication around Whitelabel-Mobile Orders
 * - Places, modifies and cancels orders. Pauses and resumes as well.
 * - Does transformation of UI form model to order placement calls
 * - Triggers state changes and showing errors when hearing acks on orders
 */
export class OrderService {
  constructor(
    private store: Store<RootState>,
    private webSocketClient: IWebSocketClient<unknown>,
    private config: { dismissesActionAfterTimeout: boolean } = { dismissesActionAfterTimeout: true }
  ) {}

  public async cancelOrder(orderID: string) {
    const clOrdID = uuid();
    this.webSocketClient.registerPublication({
      type: ORDER_CANCEL_REQUEST,
      data: [
        {
          ClOrdID: clOrdID,
          OrderID: orderID,
          TransactTime: formattedDateForSubscription(new Date()),
        },
      ],
    });

    this.store.dispatch(setOrderStep({ step: 'cancel_pending', orderID }));

    return this.createActionPromise(clOrdID)
      .then(order => {
        this.store.dispatch(setOrderStep({ step: 'cancel_confirmation', orderID: order.OrderID }));
      })
      .catch(e => {
        this.store.dispatch(setOrderStep({ step: 'cancel_failed', orderID }));
        throw e;
      })
      .finally(() => {
        if (this.config.dismissesActionAfterTimeout) {
          setTimeout(() => {
            this.store.dispatch(setOrderStep({ step: 'idle' }));
          }, EXEC_REPORT_RESPONSE_TIMEOUT_MS);
        }
      });
  }

  public pauseOrder(orderID: string) {
    const clOrdID = uuid();
    this.webSocketClient.registerPublication({
      type: ORDER_CONTROL_REQUEST,
      data: [
        {
          ClOrdID: clOrdID,
          OrderID: orderID,
          Action: OrderControlActionEnum.Pause,
          TransactTime: formattedDateForSubscription(new Date()),
        },
      ],
    });
    return clOrdID;
  }

  public resumeOrder(orderID: string) {
    const clOrdID = uuid();
    this.webSocketClient.registerPublication({
      type: ORDER_CONTROL_REQUEST,
      data: [
        {
          ClOrdID: clOrdID,
          OrderID: orderID,
          Action: OrderControlActionEnum.Resume,
          TransactTime: formattedDateForSubscription(new Date()),
        },
      ],
    });
    return clOrdID;
  }

  private createActionPromise = (clOrderID: string, expectedOrdStatus?: OrdStatusEnum[]): Promise<CustomerOrder> => {
    const subscription = new Observable<CustomerOrder>(observer => {
      const orderRequest: OrderActionRequest = {
        name: ORDER,
        tag: 'OrderService/createActionPromise',
        ClOrdID: clOrderID,
        // Its what backend expects. not ClOrdId, it expects ClOrderID.
        ClOrderID: clOrderID,
      };

      const address = uuid1();
      this.webSocketClient.registerSubscription(address, [orderRequest], (err, json) => {
        if (json?.data?.length) {
          const report = findLast(
            json.data,
            (d: CustomerOrder) =>
              d.ClOrdID === clOrderID && (expectedOrdStatus ? expectedOrdStatus.includes(d.OrdStatus) : true)
          ) as CustomerOrder | undefined;
          if (report) {
            observer.next(report);
          }
        }
      });

      return () => this.webSocketClient.unregisterSubscription(address);
    });
    return firstValueFrom(
      subscription.pipe(
        // If there is no response within the timeout, we reject the promise
        timeout(EXEC_REPORT_RESPONSE_TIMEOUT_MS)
      )
    );
  };

  public async requestOrder(): Promise<CustomerOrder | undefined> {
    const request = this.generateRequest();
    if (!request) {
      throw new Error('Unable to generate order');
    }
    this.debug('NewOrderSingle', request);
    this.store.dispatch(setOrderStep({ step: 'loading' }));

    this.webSocketClient.registerPublication({
      type: NEW_ORDER_SINGLE,
      data: [request],
    });
    return this.createActionPromise(request.ClOrdID)
      .then(order => {
        if (order.OrdStatus === OrdStatusEnum.Rejected) {
          this.store.dispatch(setOrderStep({ step: 'new_rejected', orderID: order.OrderID }));
          return;
        }
        this.store.dispatch(setOrderStep({ step: 'new_confirmation', orderID: order.OrderID }));
        return order;
      })
      .catch(() => {
        this.store.dispatch(setError('no_order_response'));
        return undefined;
      })
      .finally(() => {
        if (this.config.dismissesActionAfterTimeout) {
          const handleCleanup = () => {
            // If the orderstep is no longer newRejected or newConfirmation, it is likely that
            // the user have primed the order-form anew (or changed something within the order form),
            // and we should not clean the state if that is the case.
            const orderStep = selectOrderStep(this.store.getState());
            const error = selectError(this.store.getState());
            this.store.dispatch(setOrderStep({ step: 'idle' }));
            if (orderStep === 'new_rejected' || orderStep === 'new_confirmation' || error) {
              this.store.dispatch(cleanState({ keepSymbol: true, keepStrategy: false }));
            }
          };
          setTimeout(handleCleanup, EXEC_REPORT_RESPONSE_TIMEOUT_MS);
        }
      });
  }

  public async updateOrder() {
    const request = this.generateRequest(this.state.orderBeingModified);
    if (!request || !request.OrderID) {
      throw new Error('Unable to modify order');
    }
    this.debug('OrderCancelReplace', request);
    this.store.dispatch(setOrderStep({ step: 'loading', orderID: request.OrderID }));

    this.webSocketClient.registerPublication({
      type: ORDER_MODIFY_REQUEST,
      data: [request],
    });
    return this.createActionPromise(request.ClOrdID)
      .then(order => {
        if (order.ExecType === ExecTypeEnum.ReplaceRejected) {
          this.store.dispatch(setOrderStep({ step: 'update_rejected', orderID: order.OrderID }));
          return;
        }
        this.store.dispatch(setOrderStep({ step: 'update_confirmation', orderID: order.OrderID }));
      })
      .catch(() => {
        this.store.dispatch(setError('no_order_response'));
      })
      .finally(() => {
        if (this.config.dismissesActionAfterTimeout) {
          const handleCleanup = () => {
            // If the orderstep is no longer updateRejected or updateConfirmation, it is likely that
            // the user have primed the order-form anew (or changed something within the order form),
            // and we should not clean the state if that is the case.
            const orderStep = selectOrderStep(this.store.getState());
            const error = selectError(this.store.getState());
            this.store.dispatch(setOrderStep({ step: 'idle' }));
            if (orderStep === 'update_rejected' || orderStep === 'update_confirmation' || error) {
              this.store.dispatch(cleanState({ keepSymbol: true }));
            }
          };
          setTimeout(handleCleanup, EXEC_REPORT_RESPONSE_TIMEOUT_MS);
        }
      });
  }

  private generateRequest(order: CustomerOrder | undefined): WhiteLabelOrderCancelReplace | undefined;
  private generateRequest(): WhiteLabelNewOrderSingle | undefined;
  private generateRequest(order?: CustomerOrder): WhiteLabelNewOrderSingle | WhiteLabelOrderCancelReplace | undefined {
    const { symbolField, sideField, orderCurrencyField, quantityField, strategyField, marketAccountField } =
      this.formState;

    const isModifyingOrderWithoutStrategy = order && order.Strategy === undefined;

    // IFF modifying an order, we allow strategy to be empty
    if (strategyField.value === undefined && !isModifyingOrderWithoutStrategy) {
      return;
    }

    const transactTime = new Date();
    const parameters = { ...order?.Parameters, ...this.getParameters() };

    const modifiedParameters = cleanParametersForOrder(parameters, strategyField.value);

    const ordType: OrdTypeEnum = strategyField.value?.OrdType ?? order?.OrdType ?? OrdTypeEnum.Limit;
    let timeInForce: TimeInForceEnum = TimeInForceEnum.GoodTillCancel;

    if (ordType === OrdTypeEnum.Market) {
      delete modifiedParameters.LimitPrice;
      timeInForce = TimeInForceEnum.FillOrKill;
    }

    if (!symbolField.value || !sideField.value || !quantityField.value || !orderCurrencyField.value) {
      return;
    }

    const clOrdId = uuid();

    // modifying
    if (order) {
      const modifyRequest: WhiteLabelOrderCancelReplace = {
        Symbol: symbolField.value.Symbol,
        ClOrdID: clOrdId,
        Side: sideField.value,
        OrderQty: quantityField.value,
        Price: modifiedParameters.LimitPrice,
        Currency: orderCurrencyField.value,
        Strategy: order.Strategy,
        MarketAccount: marketAccountField.value?.SourceAccountID,
        TransactTime: formattedDateForSubscription(transactTime),
        TimeInForce: timeInForce,
        OrdType: ordType,
        Parameters: modifiedParameters,
        OrderID: order.OrderID,
      };
      return modifyRequest;
    }
    if (!strategyField.value) {
      return;
    }

    // placing new
    const request: WhiteLabelNewOrderSingle = {
      Symbol: symbolField.value.Symbol,
      ClOrdID: clOrdId,
      Side: sideField.value,
      OrderQty: quantityField.value,
      Price: modifiedParameters.LimitPrice,
      Currency: orderCurrencyField.value,
      Strategy: strategyField.value?.Name,
      MarketAccount: marketAccountField.value?.SourceAccountID,
      TransactTime: formattedDateForSubscription(transactTime),
      TimeInForce: timeInForce,
      OrdType: ordType,
      Parameters: modifiedParameters,
    };

    return request;
  }

  private getParameters(): { [key: string]: any } {
    const params = Object.keys(this.formState.parameters).reduce((acc, key) => {
      // We need to send explicit null for Dates if user cleared a previously set value; if we don't send it or send undefined the previous value will be kept
      const shouldIgnoreDate =
        this.formState.parameters[key] instanceof DateField && !this.state.orderBeingModified?.Parameters?.[key];

      const shouldIgnore = !this.formState.parameters[key].hasValue && shouldIgnoreDate;
      if (shouldIgnore) {
        return acc;
      }
      acc[key] = this.formState.parameters[key].value ?? null;
      return acc;
    }, {});
    // Force this parameter on all orders
    params['ErrorAction'] = ErrorActionEnum.Pause;
    return params;
  }

  private debug(key: string, value: any) {
    // dispatch an action so it's logged and can be debugged in the redux debugger but isn't actually handled by reducer and does not affect state in any ways
    this.store.dispatch({ type: `order/debug/${key}`, payload: value });
  }

  private get formState(): OrderFormState {
    return this.state.form;
  }

  private get state(): OrderState {
    return this.store.getState().order;
  }
}

function cleanParametersForOrder(
  currParameters: WhitelabelParametersFormState,
  selectedStrategy: WLOrderStrategy | undefined
): WhitelabelParametersFormState {
  // Don't modify original parameters obj
  const parameters = { ...currParameters };

  // If no strategy, do not clean parameters
  if (selectedStrategy) {
    // Delete Incorrect and Unused Strategy Parameters
    removeUnusedParameters(parameters, selectedStrategy.Parameters);
  }
  // format the date parameters
  formatDateParameters(parameters, selectedStrategy?.Parameters);

  // This will later be removed and set from the WLOrderStrategy subscription
  return { ...parameters, ErrorAction: ErrorActionEnum.Pause };
}

function removeUnusedParameters(
  parameters: WhitelabelParametersFormState,
  selectedParameters: WLOrderStrategyParameter[]
) {
  for (const key in parameters) {
    if (parameters[key] == null || parameters[key] === '' || !selectedParameters?.map(p => p.Name)?.includes(key)) {
      delete parameters[key];
    }
  }
  return parameters;
}

function formatDateParameters(
  parameters: WhitelabelParametersFormState,
  selectedParameters: WLOrderStrategyParameter[] | undefined
) {
  for (const key in parameters) {
    const param = selectedParameters?.find(p => p.Name === key);

    const dateValue = parameters[key];
    if (
      isDateParameterValue(dateValue, param?.Type) ||
      (parameters[key] instanceof DateField && (typeof dateValue === 'string' || isDate(dateValue)))
    ) {
      parameters[key] = param != null ? formattedDateForSubscription(dateValue) : parameters[key];
    }
  }
}
