import {
  InlineFormattedNumber,
  OrdTypeEnum,
  VStack,
  WLParameterKeysEnum,
  isCounterCurrency,
  useIntl,
  type Currency,
  type Security,
  type WLOrderStrategy,
} from '@talos/kyoko';

import { defineMessages } from 'react-intl';
import { OrderSummaryLine } from './OrderSummaryLine';

const messages = defineMessages({
  quantity: {
    id: 'OrderForms.OrderSummary.quantity',
    defaultMessage: 'Quantity',
  },
  amount: {
    id: 'OrderForms.OrderSummary.amount',
    defaultMessage: 'Amount',
  },
  limitPrice: {
    id: 'OrderForms.OrderSummary.limitPrice',
    defaultMessage: 'Limit Price',
  },
  estimatedTotal: {
    id: 'OrderForms.OrderSummary.estimatedTotal',
    defaultMessage: 'Estimated Total',
  },
});

interface OrderSummaryProps {
  estimatedAmount?: string | null;
  estimatedTotal?: string | null;
  form: Partial<{
    OrderQty: string;
    Currency: string;
    Price: string;
    Strategy: string;
  }>;
  homeCurrency?: Currency;
  security: Security;
  strategies?: WLOrderStrategy[];
}

export const OrderSummary = ({
  estimatedAmount,
  estimatedTotal,
  form,
  homeCurrency,
  security,
  strategies,
}: OrderSummaryProps) => {
  const { OrderQty, Currency, Price, Strategy } = form;
  const { BaseCurrency, QuoteCurrency, DefaultPriceIncrement, DefaultSizeIncrement, MinAmtIncrement } = security;
  const { formatMessage } = useIntl();

  const isMarketOrder = Strategy === OrdTypeEnum.Market;
  const selectedStrategy = strategies?.find(s => s.Name === Strategy);
  const usingCounterCurrency = Currency ? isCounterCurrency(Currency, security) : false;
  const counterCurrency = usingCounterCurrency ? BaseCurrency : QuoteCurrency;
  let qtyIncrement = DefaultSizeIncrement;
  if (usingCounterCurrency) {
    qtyIncrement = MinAmtIncrement ? MinAmtIncrement : DefaultPriceIncrement;
  }
  const amtIncrement = usingCounterCurrency ? DefaultSizeIncrement : DefaultPriceIncrement;

  // Show Limit Price if it exists in the state and the selected strategy supports LimitPrice
  const showLimitPrice =
    Price != null && !!selectedStrategy?.Parameters?.some(param => param.Name === WLParameterKeysEnum.LimitPrice);

  return (
    <VStack gap="spacingDefault" alignItems="space-between" fontSize="fontSizeSmall">
      <OrderSummaryLine
        title={formatMessage(messages.quantity)}
        value={
          OrderQty && (
            <InlineFormattedNumber number={OrderQty} increment={qtyIncrement} currency={Currency} align="right" />
          )
        }
      />
      <OrderSummaryLine
        title={formatMessage(messages.amount)}
        value={
          estimatedAmount != null && (
            <InlineFormattedNumber
              estimate
              number={estimatedAmount}
              round
              increment={amtIncrement}
              currency={counterCurrency}
              align="right"
            />
          )
        }
      />
      {!isMarketOrder && (
        <>
          <OrderSummaryLine
            title={formatMessage(messages.limitPrice)}
            value={
              showLimitPrice && (
                <InlineFormattedNumber
                  number={Price}
                  increment={DefaultPriceIncrement}
                  currency={QuoteCurrency}
                  align="right"
                />
              )
            }
          />
          <OrderSummaryLine
            title={formatMessage(messages.estimatedTotal)}
            value={
              estimatedTotal != null && (
                <InlineFormattedNumber
                  estimate
                  number={estimatedTotal}
                  round
                  increment={homeCurrency?.DefaultIncrement}
                  currency={homeCurrency?.Symbol}
                  align="right"
                />
              )
            }
          />
        </>
      )}
    </VStack>
  );
};
