import {
  Box,
  Checkmark,
  Crossmark,
  DISABLED_REASONABILITY_CHECK_STRATEGIES_SET,
  Dialog,
  Divider,
  EMPTY_ARRAY,
  FormattedMessage,
  HStack,
  InlineFormattedNumber,
  MixpanelEvent,
  MixpanelEventSource,
  NewOrderForm,
  NotificationVariants,
  OrdStatusEnum,
  OrdTypeEnum,
  OrderFormSides,
  Spinner,
  Text,
  Toasts,
  VStack,
  archiveOrderID,
  calculateMidPrice,
  cleanState,
  format,
  isCFD,
  logger,
  modifyOrder,
  resubmitOrderForm,
  selectBalances,
  selectError,
  selectFocusedOrderID,
  selectForm,
  selectIsFormValid,
  selectLimitPrice,
  selectModifiedOrder,
  selectOrderStep,
  selectOrderViewType,
  selectQuote,
  selectQuoteReqID,
  selectTradingLimitsValidation,
  setError,
  setFocusedOrderID,
  setMarketAccount,
  setOrderCurrency,
  setQuantity,
  setSide,
  setStrategy,
  setStrategyParams,
  setViewType,
  touchAll,
  touchField,
  unsetQuoteReqID,
  useCurrenciesContext,
  useCurrency,
  useDisclosure,
  useDynamicCallback,
  useEstimatedAmount,
  useEstimatedTotal,
  useMarketDataSnapshot,
  useMixpanel,
  useOMSDependencies,
  useObservableValue,
  useOrderDependencies,
  useOrderServiceContext,
  useRFQServiceContext,
  useSecurity,
  useToasts,
  useWLHomeCurrency,
  useWLOrderFormDispatch,
  useWLOrderFormSelector,
  useWLSymbol,
  validateMidPriceDiff,
  type CustomerOrder,
  type MarketDataSnapshot,
  type NewOrderFormProps,
  type RootState,
  type Security,
  type WLOrderStrategy,
} from '@talos/kyoko';
import type { BigSource } from 'big.js';
import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { scan } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { useOrder } from '../../hooks';
import { useGlobalCancelDialog, useStrategiesContext } from '../../providers';
import { OMS_DIALOG_WIDTH } from '../../styles/dimensions';
import { BalanceDetails } from './BalanceDetails';
import { ExecutionCard } from './ExecutionCard';
import { OrderSummary } from './OrderSummary';
import { PickQuoteView } from './PickQuoteView';
import { FeedbackMessage, MessageWrapper, OVERLAY_Z_INDEX, OmsToasts, OrderFormsWrapper, Overlay } from './styles';

/**
 * When placing order, this enum is used to determine the step of validation to perform.
 * For example, clicking place order in order form, or in a midprice modal, or in order size modal.
 * Once the validation is NONE, no checks are made and the order is sent.
 */
enum CheckValidationStep {
  MIDPRICE,
  ORDSIZE,
  NONE,
}

const getStrategiesGivenSecurity = (s: Security) => s.SupportedStrategies ?? [];
export const OrderForms = () => {
  useOMSDependencies({ getStrategiesGivenSecurity });
  return <NewOrderForms />;
};

const messages = defineMessages({
  sendingOrder: {
    defaultMessage: 'Sending order',
    id: 'OrderForms.sendingOrder',
  },
  orderSent: {
    defaultMessage: 'Order sent',
    id: 'OrderForms.orderSent',
  },
  orderFilled: {
    defaultMessage: 'Order filled',
    id: 'OrderForms.orderFilled',
  },
  quoteExpired: {
    defaultMessage: 'Quote expired',
    id: 'OrderForms.quoteExpired',
  },
  orderUpdated: {
    defaultMessage: 'Order updated',
    id: 'OrderForms.orderUpdated',
  },
  orderRejected: {
    defaultMessage: 'Order rejected',
    id: 'OrderForms.orderRejected',
  },
  modifyRejected: {
    defaultMessage: 'Modification rejected',
    id: 'OrderForms.modifyRejected',
  },
  cancelFailed: {
    defaultMessage: 'Cancel failed',
    id: 'OrderForms.cancelFailed',
  },
  orderCanceled: {
    defaultMessage: 'Order canceled',
    id: 'OrderForms.orderCanceled',
  },
  cancelOrder: {
    defaultMessage: 'Canceling order',
    id: 'OrderForms.cancelOrder',
  },
  continueConfirmation: {
    defaultMessage: 'Are you sure you want to continue?',
    id: 'OrderForms.continueConfirmation',
  },
  thresholdWarning: {
    defaultMessage: 'Quantity exceeds the threshold. Do you want to proceed with the order?',
    id: 'OrderForms.thresholdWarning',
  },
  warningThreshold: {
    defaultMessage: 'Warning Threshold',
    id: 'OrderForms.warningThreshold',
  },
  midPriceWarning: {
    defaultMessage: `Limit price {limitPrice} {quoteCurrency} is more than 5% away from mid price {midPrice} {quoteCurrency}.`,
    id: 'OrderForms.midPriceWarning',
  },
  slowToRespondToast: {
    defaultMessage: 'The server is slow to respond. If this error persists, please contact support.',
    id: 'OrderForms.slowToRespondToast',
  },
});

const NewOrderForms: FC = () => {
  useOrderDependencies();
  const dispatch = useWLOrderFormDispatch();
  const orderBeingModified = useWLOrderFormSelector(selectModifiedOrder);
  const orderFormValid = useWLOrderFormSelector(selectIsFormValid);
  const orderStep = useWLOrderFormSelector(selectOrderStep);
  const quoteReqID = useWLOrderFormSelector(selectQuoteReqID);
  const maybeQuote = useWLOrderFormSelector(selectQuote);
  const orderService = useOrderServiceContext();
  const focusedOrderID = useWLOrderFormSelector(selectFocusedOrderID);
  const orderViewType = useWLOrderFormSelector(selectOrderViewType);
  const { warn, limit } = useWLOrderFormSelector(selectTradingLimitsValidation);
  const form = useWLOrderFormSelector(selectForm);
  const { currenciesBySymbol } = useCurrenciesContext();

  const mixpanel = useMixpanel();

  const rfqService = useRFQServiceContext();

  const sendRFQForm = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.SendRfq);

    if (orderFormValid) {
      rfqService.requestQuote();
    }
  });

  const sendOrderForm = useDynamicCallback(async () => {
    if (orderBeingModified) {
      mixpanel.track(MixpanelEvent.ModifyOrder, { strategy: form.strategyField.value });
      await orderService.updateOrder();
    } else {
      mixpanel.track(MixpanelEvent.SendOrder, { strategy: form.strategyField.value });
      await orderService.requestOrder();
    }
  });

  const handleViewTypeChange: NewOrderFormProps['handleViewTypeChange'] = useDynamicCallback(viewType => {
    dispatch(setViewType(viewType));
  });

  const handleClearForm: NewOrderFormProps['handleClearForm'] = useDynamicCallback(() => {
    if (maybeQuote || quoteReqID) {
      mixpanel.track(MixpanelEvent.CancelRfq);
      rfqService.cancelQuote(maybeQuote?.RFQID, quoteReqID ?? maybeQuote?.QuoteReqID);
      dispatch(unsetQuoteReqID());
    }
    dispatch(cleanState({ keepStrategy: true, keepSymbol: true }));
  });

  const handleQuantityChange: NewOrderFormProps['handleQuantityChange'] = useDynamicCallback(
    (qty: string | undefined) => {
      dispatch(setQuantity(qty));
    }
  );
  const handleOrderCurrencyChange: NewOrderFormProps['handleOrderCurrencyChange'] = useDynamicCallback(
    (ccy: string) => {
      dispatch(setOrderCurrency(ccy));
    }
  );

  const handleSideChange: NewOrderFormProps['handleSideChange'] = useDynamicCallback((side: OrderFormSides) => {
    dispatch(setSide(side));
  });
  const handleStrategyChange: NewOrderFormProps['handleStrategyChange'] = useDynamicCallback(
    (strategy: WLOrderStrategy | undefined) => {
      dispatch(setStrategy(strategy));
    }
  );
  const handleStrategyParamChange: NewOrderFormProps['handleStrategyParamChange'] = useDynamicCallback(
    (key: string, value: any) => {
      dispatch(setStrategyParams({ key, value }));
    }
  );

  const { setSymbol: setGlobalSymbol } = useWLSymbol();

  const handleSymbolChange: NewOrderFormProps['handleSymbolChange'] = useDynamicCallback(symbol => {
    if (symbol) {
      mixpanel.track(MixpanelEvent.ChangeSymbol, { source: MixpanelEventSource.OrderForm });
      setGlobalSymbol(symbol);
    }
  });

  const handleMarketAccountChange: NewOrderFormProps['handleMarketAccountChange'] = useDynamicCallback(account => {
    dispatch(setMarketAccount(account));
  });

  const handleTouchAll: NewOrderFormProps['handleTouchAll'] = useDynamicCallback(() => {
    dispatch(touchAll());
  });

  const handleTouchField: NewOrderFormProps['handleTouchField'] = useDynamicCallback((field: string) => {
    dispatch(touchField(field));
  });

  const security = form.symbolField.value;
  const marketDataSnapshotQuantity = form.quantityField.value || security?.NormalSize || '1';
  const marketDataSnapshotMarkets = useMemo(
    () =>
      form.marketAccountField.value?.SourceAccountID ? [form.marketAccountField.value.SourceAccountID] : EMPTY_ARRAY,
    [form.marketAccountField.value?.SourceAccountID]
  );
  const marketDataSnapshot = useMarketDataSnapshot({
    priceIncrement: security?.DefaultPriceIncrement,
    symbol: security?.Symbol,
    quantity: marketDataSnapshotQuantity,
    currency: form.orderCurrencyField.value,
    baseCurrency: security?.PositionCurrency || security?.BaseCurrency,
    tag: 'OMS/Order',
    // @ts-expect-error [sc-71645] Whitelabel UI Shouldn't Subscribe to "Market" Sub
    // All markets in Whitelabel are filtered to be Active
    markets: marketDataSnapshotMarkets,
  });

  const maxOrderSizeDialog = useDisclosure();
  const midPriceDialog = useDisclosure();

  const [midPriceToDisplay, setMidPriceToDisplay] = useState<BigSource | undefined | null>(null);
  const { midPrice, bidPrice, offerPrice } = useObservableValue(
    () =>
      marketDataSnapshot.marketDataSnapshots.pipe(
        scan<MarketDataSnapshot, { midPrice: BigSource | undefined | null; bidPrice?: string; offerPrice?: string }>(
          (res, snapshot: MarketDataSnapshot) => {
            const bidPrice = snapshot.Bids?.at(0)?.Price;
            const offerPrice = snapshot.Offers?.at(0)?.Price;
            const midPrice = calculateMidPrice(bidPrice, offerPrice);
            return { midPrice: midPrice, offerPrice, bidPrice };
          },
          { midPrice: null }
        )
      ),
    [marketDataSnapshot.marketDataSnapshots],
    { midPrice: null }
  );

  // We don't want to update the midprice whilst the dialog is showing.
  useEffect(() => {
    if (midPrice && !midPriceDialog.isOpen) {
      setMidPriceToDisplay(midPrice);
    }
  }, [midPrice, midPriceDialog.isOpen]);

  const submitForm: NewOrderFormProps['handleFormSubmit'] = useDynamicCallback(() => {
    if (orderViewType.value === 'order') {
      sendOrderForm();
    } else if (orderViewType.value === 'rfq') {
      mixpanel.track(MixpanelEvent.SendRfq);
      sendRFQForm();
    }
  });

  const maybeLimitPrice = useWLOrderFormSelector(selectLimitPrice);

  const handleFormSubmit = useDynamicCallback((step: CheckValidationStep) => {
    const shouldCheckReasonability =
      form.strategyField.value?.Name != null &&
      !DISABLED_REASONABILITY_CHECK_STRATEGIES_SET.has(form.strategyField.value.Name);

    // check mid price
    if (
      step <= CheckValidationStep.MIDPRICE &&
      shouldCheckReasonability &&
      !validateMidPriceDiff(midPrice, maybeLimitPrice?.value)
    ) {
      midPriceDialog.open();
      return;
    }
    // look up the trading limit thresholds
    if (step <= CheckValidationStep.ORDSIZE && warn) {
      maxOrderSizeDialog.open();
      return;
    }
    submitForm();
  });

  const balances = useWLOrderFormSelector(selectBalances);

  const { strategiesByName } = useStrategiesContext();
  const globalCancelDialog = useGlobalCancelDialog();

  const activeOrder = useOrder({ orderID: focusedOrderID ?? undefined });
  const activeOrderSecurity = useSecurity(activeOrder?.Symbol);
  const activeOrderStrategy = activeOrder?.Strategy ? strategiesByName.get(activeOrder.Strategy) : undefined;

  const onCloseCard = useDynamicCallback(() => {
    dispatch(setFocusedOrderID(null));
  });

  const onCancelOrder = useDynamicCallback((orderID: string) => {
    mixpanel.track(MixpanelEvent.CancelOrder);
    orderService.cancelOrder(orderID).catch(() => {
      globalCancelDialog.open(orderID, true);
    });
  });

  const onArchiveOrder = useDynamicCallback((orderID: string) => {
    mixpanel.track(MixpanelEvent.ArchiveOrder);
    dispatch(archiveOrderID(orderID));
    dispatch(setFocusedOrderID(null));
  });

  const onResubmitOrder = useDynamicCallback((order: CustomerOrder) => {
    mixpanel.track(MixpanelEvent.OpenResubmitOrder);
    dispatch(resubmitOrderForm(order));
  });

  const onModifyOrder = useDynamicCallback((order: CustomerOrder) => {
    mixpanel.track(MixpanelEvent.OpenModifyOrder);
    dispatch(modifyOrder(order));
  });

  const currentQuote = useWLOrderFormSelector((state: RootState) => state.order.quote);

  const confirmMidPrice = useDynamicCallback(() => {
    handleFormSubmit(CheckValidationStep.ORDSIZE);
  });

  const confirmMaxOrderSize = useDynamicCallback(() => {
    handleFormSubmit(CheckValidationStep.NONE);
  });

  const { homeCurrency } = useWLHomeCurrency();
  const homeCurrencyInfo = useCurrency(homeCurrency);

  const estimatedAmount = useEstimatedAmount({
    LimitPrice: maybeLimitPrice?.isVisible ? maybeLimitPrice.value : undefined,
    OrderQty: form.quantityField.value,
    Currency: form.orderCurrencyField.value,
    security,
    marketRate:
      form.strategyField.value?.OrdType === OrdTypeEnum.Market
        ? form.sideField.value === OrderFormSides.Buy
          ? offerPrice
          : bidPrice
        : undefined,
  });

  const estimatedTotal = useEstimatedTotal({
    security,
    quantity: form.quantityField.value,
    quantityCurrency: form.orderCurrencyField.value,
    price: maybeLimitPrice?.isVisible ? maybeLimitPrice.value : undefined,
  });

  const loading = orderStep === 'loading';
  const newConfirmation = orderStep === 'new_confirmation';
  const updateConfirmation = orderStep === 'update_confirmation';
  const updateRejected = orderStep === 'update_rejected';
  const newRejected = orderStep === 'new_rejected';
  const rfqExpired = orderStep === 'rfq_expired';
  const cancelFailed = orderStep === 'cancel_failed';
  const cancelling = orderStep === 'cancel_pending';
  const canceled = orderStep === 'cancel_confirmation';

  const showOverlay =
    loading ||
    newConfirmation ||
    updateConfirmation ||
    updateRejected ||
    newRejected ||
    cancelFailed ||
    cancelling ||
    rfqExpired ||
    canceled;

  const handleClickSubmitForm = useCallback(() => {
    handleFormSubmit(CheckValidationStep.MIDPRICE);
  }, [handleFormSubmit]);

  const error = useWLOrderFormSelector(selectError);
  const { toasts, add: addToast, remove: removeToast } = useToasts();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (error && error === 'no_order_response') {
      logger.error(new Error('Did not recieve order back with matching ClOrdID'));
      addToast({
        id: uuid(),
        text: formatMessage(messages.slowToRespondToast),
        variant: NotificationVariants.Warning,
        dismissable: true,
        timeout: 10_000,
      });
      dispatch(setError(null));
    }
  }, [error, addToast, dispatch, formatMessage]);

  return (
    <OrderFormsWrapper>
      {showOverlay && (
        <Overlay data-testid="overlay-order-form">
          {loading && (
            <MessageWrapper data-testid="overlay-message-sending-order">
              <Spinner />
              <FeedbackMessage>
                <FormattedMessage {...messages.sendingOrder} />
              </FeedbackMessage>
            </MessageWrapper>
          )}
          {newConfirmation && (
            <MessageWrapper data-testid="overlay-message-confirm">
              <Checkmark />
              <FeedbackMessage>
                {activeOrder?.OrdStatus === OrdStatusEnum.Filled ? (
                  <FormattedMessage {...messages.orderFilled} />
                ) : (
                  <FormattedMessage {...messages.orderSent} />
                )}
              </FeedbackMessage>
            </MessageWrapper>
          )}
          {newRejected && (
            <MessageWrapper data-testid="overlay-message-rejected">
              <Crossmark />
              <FeedbackMessage>{newRejected && <FormattedMessage {...messages.orderRejected} />}</FeedbackMessage>
            </MessageWrapper>
          )}
          {updateConfirmation && (
            <MessageWrapper data-testid="overlay-message-updated-order">
              <Checkmark />
              <FeedbackMessage>
                <FormattedMessage {...messages.orderUpdated} />
              </FeedbackMessage>
            </MessageWrapper>
          )}

          {updateRejected && (
            <MessageWrapper data-testid="overlay-message-updated-rejected">
              <Crossmark />
              <FeedbackMessage>
                <FormattedMessage {...messages.modifyRejected} />
              </FeedbackMessage>
            </MessageWrapper>
          )}
          {rfqExpired && (
            <MessageWrapper data-testid="overlay-message-quote-expired">
              <Crossmark />
              <FeedbackMessage>
                <FormattedMessage {...messages.quoteExpired} />
              </FeedbackMessage>
            </MessageWrapper>
          )}

          {cancelFailed && (
            <MessageWrapper data-testid="overlay-message-cancel-failed">
              <Crossmark />
              <FeedbackMessage>{cancelFailed && <FormattedMessage {...messages.cancelFailed} />}</FeedbackMessage>
            </MessageWrapper>
          )}
          {cancelling && (
            <MessageWrapper data-testid="overlay-message-cancelling">
              <Spinner />
              <FeedbackMessage>
                <FormattedMessage {...messages.cancelOrder} />
              </FeedbackMessage>
            </MessageWrapper>
          )}
          {canceled && (
            <MessageWrapper data-testid="overlay-message-canceled">
              <Checkmark />
              <FeedbackMessage>
                <FormattedMessage {...messages.orderCanceled} />
              </FeedbackMessage>
            </MessageWrapper>
          )}
        </Overlay>
      )}

      <NewOrderForm
        quoteReqID={quoteReqID}
        handleSymbolChange={handleSymbolChange}
        handleTouchAll={handleTouchAll}
        handleTouchField={handleTouchField}
        handleClearForm={handleClearForm}
        handleFormSubmit={handleClickSubmitForm}
        handleQuantityChange={handleQuantityChange}
        handleOrderCurrencyChange={handleOrderCurrencyChange}
        handleSideChange={handleSideChange}
        handleStrategyChange={handleStrategyChange}
        handleStrategyParamChange={handleStrategyParamChange}
        handleMarketAccountChange={handleMarketAccountChange}
        marketDataSnapshot={marketDataSnapshot}
        orderCcyField={form.orderCurrencyField}
        parameters={form.parameters}
        symbolField={form.symbolField}
        marketAccountField={form.marketAccountField}
        quantityField={form.quantityField}
        security={form.symbolField.value}
        sideField={form.sideField}
        strategyField={form.strategyField}
        orderFormValid={orderFormValid}
        orderStep={orderStep}
        orderBeingModified={orderBeingModified}
        balances={balances}
        balanceDetails={
          security != null && !isCFD(security) ? (
            <BalanceDetails accountID={form.marketAccountField.value?.SourceAccountID} security={security} />
          ) : null
        }
        orderSummary={
          security && (
            <OrderSummary
              estimatedAmount={estimatedAmount}
              estimatedTotal={estimatedTotal}
              form={{
                Currency: form.orderCurrencyField.value,
                OrderQty: form.quantityField.value,
                Price: maybeLimitPrice?.value,
                Strategy: form.strategyField.value?.Name,
              }}
              homeCurrency={homeCurrencyInfo}
              security={security}
              strategies={form.strategyField.availableItems}
            />
          )
        }
        orderViewType={orderViewType}
        handleViewTypeChange={handleViewTypeChange}
        pickQuoteView={orderViewType.value === 'rfq' && quoteReqID ? <PickQuoteView quote={currentQuote} /> : null}
      />
      {activeOrder && activeOrderSecurity && (
        <Box zIndex={OVERLAY_Z_INDEX + 1}>
          <ExecutionCard
            order={activeOrder}
            security={activeOrderSecurity}
            strategy={activeOrderStrategy}
            onCloseCard={onCloseCard}
            onCancelOrder={onCancelOrder}
            onArchiveOrder={onArchiveOrder}
            onResubmitOrder={onResubmitOrder}
            onModifyOrder={onModifyOrder}
          />
        </Box>
      )}

      <Dialog width={OMS_DIALOG_WIDTH} usePortal={false} {...midPriceDialog} onConfirm={confirmMidPrice}>
        <VStack gap="spacingMedium">
          <div>
            <FormattedMessage
              {...messages.midPriceWarning}
              values={{
                quoteCurrency: security?.QuoteCurrency,
                limitPrice: format(maybeLimitPrice?.value, {
                  spec: security?.DefaultPriceIncrement,
                  pretty: true,
                }),
                midPrice: format(midPriceToDisplay, {
                  spec: security?.DefaultPriceIncrement,
                  pretty: true,
                  round: true,
                }),
              }}
            />
          </div>
          <div>
            <FormattedMessage {...messages.continueConfirmation} />
          </div>
        </VStack>
      </Dialog>
      <Dialog {...maxOrderSizeDialog} usePortal={false} onConfirm={confirmMaxOrderSize}>
        {limit && (
          <>
            <Text color="colorTextAttention">
              <FormattedMessage {...messages.thresholdWarning} />
            </Text>
            <Box mt="spacingMedium">
              <HStack justifyContent="space-between">
                <FormattedMessage {...messages.warningThreshold} />
                <InlineFormattedNumber
                  number={limit.WarnThreshold}
                  currency={limit.ThresholdCurrency}
                  increment={limit.Currency ? currenciesBySymbol.get(limit.Currency)?.DefaultIncrement : undefined}
                />
              </HStack>
              <Divider mt="spacingDefault" />
            </Box>
          </>
        )}
      </Dialog>
      {toasts?.length > 0 && (
        <OmsToasts>
          <Toasts toasts={toasts} remove={removeToast} />
        </OmsToasts>
      )}
    </OrderFormsWrapper>
  );
};
