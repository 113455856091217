import type { GridReadyEvent, RowDragEvent, SortChangedEvent } from 'ag-grid-community';
import type { ColDef, GridApi, GridOptions, RowNode, ValueGetterFunc } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { compact, inRange } from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { useTheme } from 'styled-components';
import { SecurityTypesOptions, useFavoriteSecurities, useMixpanel, useWatchlistSettings } from '../../contexts';
import { useDeviceType, useDynamicCallback } from '../../hooks';
import { MixpanelEvent, MixpanelEventProperty, MixpanelEventSource } from '../../tokens';
import type { Security } from '../../types';
import { ProductTypeEnum } from '../../types';
import { BlotterDensity, BlotterTableWrapper } from '../BlotterTable';
import { WatchlistFilters } from './WatchListFilters';
import { useDynamicMarketPriceSubscription } from './hooks/useDynamicMarketPriceSubscription';
import { useDynamicRefRateSubscription } from './hooks/useDynamicRefRateSubscription';
import { useDynamicSparklineSubscription } from './hooks/useDynamicSparklineSubscription';
import { useSecuritiesList } from './hooks/useSecuritiesList';
import { WatchListWrapper } from './styles';
import type { WatchListProps, WatchListRowData, WatchListRowNode } from './types';
import { useColumns } from './useColumns';

export type { WatchListColumnTypes, WatchListProps, WatchListRowData, WatchListRowNode } from './types';

export const WatchList = memo(function WatchList({
  rowHeight = 48,
  selectableCurrencies,
  enabledColumns,
  securityTypesOptions,
  extraGridOptions,
  showFavorites,
}: WatchListProps) {
  const mixpanel = useMixpanel();
  const {
    showOnlyFavorites: showOnlyFavoritesSetting,
    setShowOnlyFavorites,
    securityTypes,
    setSecurityTypes,
    filteredCurrencies,
    setFilteredCurrencies,
    layout,
  } = useWatchlistSettings();
  const showOnlyFavorites = showFavorites ?? showOnlyFavoritesSetting;
  const { favoriteSecurities, setFavoriteSecurities } = useFavoriteSecurities();
  const favoriteSecuritiesSet = useMemo(() => new Set(favoriteSecurities), [favoriteSecurities]);
  const isFavorite = useDynamicCallback((s: Security) => favoriteSecuritiesSet.has(s.Symbol));

  const [searchText, setSearchText] = useState('');

  const securities = useSecuritiesList({ favoriteSecurities });

  const toggleFavorite = useDynamicCallback((security: Security) => {
    const newFavorites = favoriteSecuritiesSet;
    if (newFavorites.has(security.Symbol)) {
      newFavorites.delete(security.Symbol);
    } else {
      mixpanel.track(MixpanelEvent.FavoriteSymbol, { [MixpanelEventProperty.Source]: MixpanelEventSource.Watchlist });
      newFavorites.add(security.Symbol);
    }
    setFavoriteSecurities(compact([...newFavorites.values()]));
  });
  const updateSearchText: React.ChangeEventHandler<HTMLInputElement> = useDynamicCallback(e =>
    setSearchText(e.target.value)
  );

  const getRank = useDynamicCallback<ValueGetterFunc>(params => {
    const rowData = params.data as WatchListRowData;
    const security = rowData.security;
    const rank = showOnlyFavorites ? favoriteSecurities.indexOf(security.Symbol) : security.Rank;
    return rank;
  });
  const rankComparator = useDynamicCallback<Exclude<ColDef['comparator'], undefined>>(
    (valueA, valueB, rowNodeA: WatchListRowNode, rowNodeB: WatchListRowNode) => {
      if (showOnlyFavorites) {
        valueA = favoriteSecurities.indexOf(rowNodeA.data.security.Symbol);
        valueB = favoriteSecurities.indexOf(rowNodeB.data.security.Symbol);
      }
      if (valueA === valueB) {
        return 0;
      }
      return (valueA ?? Number.MAX_VALUE) > (valueB ?? Number.MAX_VALUE) ? 1 : -1;
    }
  );
  const selectedCurrencies = useMemo(() => new Set(filteredCurrencies), [filteredCurrencies]);
  const doesExternalFilterPass = useDynamicCallback((node: RowNode) => {
    const row = node.data as WatchListRowData;
    const s = row.security;
    if (showOnlyFavorites && !isFavorite(s)) {
      return false;
    }
    if (searchText !== '') {
      const searchRegex = new RegExp(searchText, 'ig');
      if (!searchRegex.test(s.DisplaySymbol ?? '') && !searchRegex.test(s.searchSymbol ?? '')) {
        return false;
      }
    }
    switch (securityTypes) {
      case SecurityTypesOptions.Perp:
        if (s.ProductType !== ProductTypeEnum.PerpetualSwap) {
          return false;
        }

        break;
      case SecurityTypesOptions.Spot:
        if (s.ProductType !== ProductTypeEnum.Spot) {
          return false;
        }
        break;

      case SecurityTypesOptions.Future:
        if (s.ProductType !== ProductTypeEnum.Future) {
          return false;
        }
        break;

      case SecurityTypesOptions.CalendarSpread:
        if (s.ProductType !== ProductTypeEnum.CalendarSpread) {
          return false;
        }
        break;

      case SecurityTypesOptions.All:
        // At least for now we never wan't to display Options or Multilegs
        if (s.ProductType === ProductTypeEnum.Option || s.ProductType === ProductTypeEnum.Synthetic) {
          return false;
        }
        break;
    }
    if (selectedCurrencies.size > 0) {
      if (!(selectedCurrencies.has(s.BaseCurrency) || selectedCurrencies.has(s.SettlementCurrency))) {
        return false;
      }
    }
    return true;
  });
  const theme = useTheme();

  const onRowDragMove = useDynamicCallback((event: RowDragEvent) => {
    const movingNode = event.node as WatchListRowNode | undefined;
    const overNode = event.overNode as WatchListRowNode | undefined;
    const rowNeedsToMove = movingNode !== overNode;
    if (rowNeedsToMove) {
      const movingSymbol = movingNode?.data?.security?.Symbol;
      const overSymbol = overNode?.data?.security?.Symbol;
      if (movingSymbol && overSymbol) {
        const fromIndex = favoriteSecurities.indexOf(movingSymbol);
        const toIndex = favoriteSecurities.indexOf(overSymbol);

        if (!inRange(fromIndex, 0, favoriteSecurities.length) || !inRange(toIndex, 0, favoriteSecurities.length)) {
          throw new Error(
            `Can't move ${movingSymbol} from ${fromIndex} to ${toIndex} (swapping with ${overSymbol}) - outside of range [0, ${favoriteSecurities.length}]`
          );
        }

        const newStore = favoriteSecurities.slice();
        moveInArray(newStore, fromIndex, toIndex);
        setFavoriteSecurities(compact(newStore));
      }
    }
    function moveInArray(arr: string[], fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  });

  const onSortChanged = useDynamicCallback((event: SortChangedEvent) => {
    const columnState = event.columnApi.getColumnState();
    if (!columnState.some(c => c.sort)) {
      event.columnApi.applyColumnState({ state: [{ colId: 'rank', sort: 'asc' }], defaultState: { sort: null } });
    }
  });

  const [volumeWindow, setVolumeWindow] = useState<string>('24H');

  const deviceType = useDeviceType();
  const isMobile = deviceType === 'mobile';

  const columns = useColumns({
    isFavorite,
    toggleFavorite,
    onShowOnlyFavorites: setShowOnlyFavorites,
    showOnlyFavorites,

    volumeWindow,
    getRank,
    rankComparator,

    enabledColumns,
    isMobile,
  });

  const gridOptions = useMemo<GridOptions<WatchListRowData>>(
    () => ({
      rowHeight,
      scrollbarWidth: 12,
      alwaysShowVerticalScroll: true,
      suppressContextMenu: true,
      getRowId: ({ data }) => data?.security?.Symbol,
      getMainMenuItems: params => {
        if (params.column.getColDef().headerName === 'Volume') {
          return [1, 4, 24].map(time => ({
            name: `${time}H`,
            action: () => {
              setVolumeWindow(`${time}H`);
              params.api.refreshHeader();
            },
          }));
        }
        return [];
      },
      onRowDragMove,
      isExternalFilterPresent: () => true,
      doesExternalFilterPass,
      suppressRowDrag: true,
      defaultColDef: {
        cellRendererParams: {
          theme,
        },
        suppressMenu: true,
        sortable: false,
      },
      getRowStyle: ({ data }) => ({
        opacity:
          data?.refRate?.currentPrice != null ||
          data?.sparklineData?.Volume != null ||
          data?.sparklineData != null ||
          data?.bid != null ||
          data?.offer != null ||
          data?.spread != null
            ? '1'
            : '0.5',
      }),
      onSortChanged,
      ...extraGridOptions,
    }),
    [doesExternalFilterPass, onRowDragMove, onSortChanged, rowHeight, theme, extraGridOptions]
  );
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const onGridReady = useDynamicCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
  });

  useEffect(() => {
    window.setTimeout(() => {
      gridApi?.onFilterChanged();
      gridApi?.setSuppressRowDrag(!showOnlyFavorites);
    }, 0);
  }, [doesExternalFilterPass, gridApi, securityTypes, isFavorite, showOnlyFavorites, searchText, filteredCurrencies]);

  useDynamicRefRateSubscription(gridApi, enabledColumns);
  useDynamicSparklineSubscription(gridApi, enabledColumns);
  useDynamicMarketPriceSubscription(gridApi, enabledColumns);

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    const transactions = { add: [] as WatchListRowData[], update: [] as WatchListRowData[] };
    for (const d of securities) {
      const id = d.security.Symbol;

      const row = gridApi.getRowNode(id);
      if (row == null) {
        transactions.add.push(d);
      } else {
        transactions.update.push(d);
      }
    }
    gridApi.applyTransactionAsync(transactions);
  }, [gridApi, securities]);

  useFavoritesInGrid(gridApi, favoriteSecurities);

  return (
    <WatchListWrapper showOnlyFavorites={showOnlyFavorites}>
      <WatchlistFilters
        onShowOnlyFavorites={setShowOnlyFavorites}
        showOnlyFavorites={showOnlyFavorites}
        onSecurityTypeChange={setSecurityTypes}
        securityType={securityTypes}
        filteredCurrencies={filteredCurrencies}
        onFilteredCurrenciesChange={setFilteredCurrencies}
        onSearchTextChange={updateSearchText}
        searchText={searchText}
        selectableCurrencies={selectableCurrencies}
        layout={layout}
        securityTypesOptions={securityTypesOptions}
      />
      <BlotterTableWrapper
        data-testid="watchlist-wrapper"
        className="ag-theme-balham-dark"
        density={BlotterDensity.Compact}
      >
        <AgGridReact onGridReady={onGridReady} columnDefs={columns} gridOptions={gridOptions} />
      </BlotterTableWrapper>
    </WatchListWrapper>
  );
});

function useFavoritesInGrid(gridApi: GridApi | null, favoriteSecurities: string[]) {
  const prevFavorites = usePrevious<string[]>(favoriteSecurities);
  useEffect(() => {
    if (!gridApi) {
      return;
    }
    const currFavoritesSet = new Set(favoriteSecurities);
    const prevFavoritesSet = new Set(prevFavorites);

    const transactions = { update: [] as WatchListRowData[] };
    for (const id of currFavoritesSet) {
      const row = gridApi.getRowNode(id);
      if (row) {
        transactions.update.push(row.data);
      }
    }
    for (const id of prevFavoritesSet ?? []) {
      if (!currFavoritesSet.has(id)) {
        const row = gridApi.getRowNode(id);
        if (row) {
          transactions.update.push(row.data);
        }
      }
    }
    gridApi.applyTransactionAsync(transactions);
  }, [gridApi, favoriteSecurities, prevFavorites]);
}
